<template>
  <div class="auth-wrapper auth-v2">
    <b-link class="brand-logo">
      <b-img
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Surfe logo"
        style="max-width:120px"
      />
    </b-link>

    <b-row class="auth-inner m-0">
      <!-- Cover -->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center auth-bg p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="require('@/assets/images/subscribe.svg')"
            alt="Login"
            class="ml-auto"
            style="width:50%"
          />
        </div>
      </b-col>

      <!-- Feedback-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          lg="8"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-base"
          >
            Unsubscribe
          </b-card-title>

          <b-card-text class="mb-2">
            Please choose for what purpose you allow us to send you some emails.
          </b-card-text>

          <b-form-checkbox
            v-model="account.enableTrialEmails"
            switch
          >
            Allow trial emails
          </b-form-checkbox>

          <b-alert
            :show="allDisabled"
            variant="primary"
            class="mt-2"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-1"
                icon="InfoIcon"
              />
              <span class="ml-1">You won't receive any emails.</span>
            </div>
          </b-alert>

          <b-button
            class="mt-3 px-3"
            variant="primary"
            @click="save"
          >
            Save preferences
          </b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg, BButton, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BFormCheckbox,
    BAlert,
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
    }),
    allDisabled() {
      return (
        !this.account.enableTrialEmails
      )
    },
  },
  mounted() {
    this.$store.dispatch('api/loadNotificationSettings', this.$route.params)
  },
  methods: {
    async save() {
      try {
        await this.$store.dispatch('api/updateNotificationSettings', { ...this.account, ...this.$route.params })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Preferences saved',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
